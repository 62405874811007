import React from 'react'
import PropTypes from 'prop-types'

import Header from './header'
import Footer from './footer'

import '../_styles/normalize.css'
import '../_styles/estilos.css'

const Layout = ({children}) => (
	<React.Fragment>
		<Header />
		<div className="single">
			<main className="container">{children}</main>
		</div>
		<Footer />
	</React.Fragment>
)

Layout.propTypes = {
	children: PropTypes.node.isRequired,
}

export default Layout
