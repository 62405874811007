import React from 'react'
import {StaticQuery, graphql} from 'gatsby'
import addToMailchimp from 'gatsby-plugin-mailchimp'

class MailchimpForm extends React.Component {
	state = {
		email: ``,
		status: {
			type: `init`,
		},
	}

	async onSubmit(e) {
		e.preventDefault()

		try {
			const {email} = this.state

			this.setState({status: {type: `submitting`}})
			const response = await addToMailchimp(email)
			this.setState({
				email: response.result === `success` ? `` : email,
				status: {type: `submitted`, response, email},
			})
		} catch (error) {
			console.log(error)
			this.setState({status: {type: `failed`, error}})
		}
	}

	render() {
		const {email, status} = this.state

		return (
			<form onSubmit={::this.onSubmit}>
				<fieldset>
					<input
						type="email"
						className="sign-up-input"
						disabled={status.type === `submitting`}
						value={email}
						onChange={e => this.setState({email: e.target.value})}
					/>
					<input
						type="submit"
						className="sign-up-button"
						disabled={status.type === `submitting`}
						value="Go"
					/>
					{status.type === `submitting` && (
						<p className="submission-feedback">Submitting your email now...</p>
					)}
					{status.type === `submitted` && (
						<p className="submission-feedback">{status.response.msg}</p>
					)}
					{status.type === `failed` && (
						<p className="submission-feedback failed">
							Your submission failed, please try again.
						</p>
					)}
				</fieldset>
			</form>
		)
	}
}

const Footer = ({contact}) => (
	<footer className="footer">
		<div className="container">
			<h3 className="sign-up-title">Sign up for our newsletter</h3>
			<div className="footer-contact">
				<MailchimpForm />
			</div>
			<div className="footer-information">
				<p>
					<span className="footer-label">EMAIL:</span>&nbsp;
					<a href={`mailto:${contact.email}`}>{contact.email}</a>
				</p>
				<p>
					<span className="footer-label">HEADQUARTERS:</span>{' '}
					{contact.headquarters}
				</p>
				<p>
					<span className="footer-label">BRANCHES:</span>{' '}
					{contact.branches.join(`-`)}
				</p>
			</div>
			<div className="footer-copy">
				<p>
					©{new Date().getFullYear()} Golden Ceiba Productions. All rights
					reserved | Design by <a href="http://www.behance.net/dgivan">CDK</a>
				</p>
			</div>
		</div>
	</footer>
)

export default () => (
	<StaticQuery
		query={graphql`
			{
				site {
					m: siteMetadata {
						contact {
							phone
							email
							headquarters
							branches
						}
					}
				}
			}
		`}
		render={data => <Footer {...data.site.m} />}
	/>
)
