import React from 'react'
import {StaticQuery, graphql, Link} from 'gatsby'

import * as Icons from '../components/icons'
import logo from '../_styles/images/logo.png'

class Header extends React.Component {
	state = {
		menuActive: false,
	}

	render() {
		const {social} = this.props
		const {menuActive} = this.state

		return (
			<header className="general">
				<div className="logo">
					<Link to="/">
						<img alt="golden ceiba productions logo" src={logo} />
					</Link>
				</div>
				<nav id="navigation" className={menuActive ? `active` : ``}>
					<button
						aria-controls="menu-list"
						className="menu-icon"
						onClick={() => this.setState({menuActive: !menuActive})}
					>
						<span>Menu</span>
						<Icons.Menu />
					</button>
					<ul id="menu-list">
						<li>
							<Link activeClassName="active" to="/">
								Home
							</Link>
						</li>
						<li>
							<Link activeClassName="active" to="/about-us">
								About Us
							</Link>
						</li>
						<li>
							<Link activeClassName="active" to="/films">
								Films
							</Link>
						</li>
						<li>
							<Link activeClassName="active" to="/facilities">
								Facilities and Rentals
							</Link>
						</li>
						<li>
							<Link activeClassName="active" to="/branding">
								Branding
							</Link>
						</li>
						<li>
							<Link activeClassName="active" to="/gallery">
								Gallery
							</Link>
						</li>
						<li>
							<Link activeClassName="active" to="/contact">
								Contact us
							</Link>
						</li>
					</ul>
				</nav>
				<div className="iconos">
					<div className="iconitos">
						<a href={social.instagram}>
							<Icons.Instagram />
						</a>{' '}
					</div>
					<div className="iconitos">
						<a href={social.vimeo}>
							<Icons.Vimeo />
						</a>{' '}
					</div>
					<div className="iconitos">
						<a href={social.twitter}>
							<Icons.Twitter />
						</a>
					</div>
					<div className="iconitos">
						<a href={social.facebook}>
							<Icons.Facebook />
						</a>{' '}
					</div>
				</div>
			</header>
		)
	}
}

export default () => (
	<StaticQuery
		query={graphql`
			{
				site {
					m: siteMetadata {
						social {
							facebook
							twitter
							vimeo
							instagram
						}
					}
				}
			}
		`}
		render={data => <Header {...data.site.m} />}
	/>
)
