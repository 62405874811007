import React from 'react'

export const Menu = () => (
	<svg version="1.1" viewBox="0 0 32 32">
		<title>Menu Icon</title>
		<desc>M</desc>
		<path
			fill="#8f7400"
			d="M4,10h24c1.104,0,2-0.896,2-2s-0.896-2-2-2H4C2.896,6,2,6.896,2,8S2.896,10,4,10z M28,14H4c-1.104,0-2,0.896-2,2  s0.896,2,2,2h24c1.104,0,2-0.896,2-2S29.104,14,28,14z M28,22H4c-1.104,0-2,0.896-2,2s0.896,2,2,2h24c1.104,0,2-0.896,2-2  S29.104,22,28,22z"
		/>
	</svg>
)

export const Twitter = () => (
	<svg version="1.1" id="TwitterIcon" viewBox="0 0 210 279.9">
		<title>Twitter Icon</title>
		<desc>T</desc>
		<path
			className="fillPath"
			fill="#8f7400"
			d="M165.5,268.2H94.3l-1.5-0.1c-48.4-4.4-80.8-40.8-80.5-90.3V41.8c0-17.7,14.3-32,32-32s32,14.3,32,32v47.2
      l92.9,0.9c17.7,0.2,31.9,14.6,31.7,32.3c-0.2,17.6-14.5,31.7-32,31.7c-0.1,0-0.2,0-0.3,0L76.3,153v24.9
      c-0.1,22.7,14.1,25.6,21,26.3h68.2c17.7,0,32,14.3,32,32S183.2,268.2,165.5,268.2z"
		/>
	</svg>
)

export const Vimeo = () => (
	<svg version="1.1" id="VimeoIcon" x="0px" y="0px" viewBox="0 0 20.847 20.847">
		<title>Vimeo Icon</title>
		<desc>V</desc>
		<path
			fill="#8f7400"
			className="fillPath"
			d="M17.811,2.018c2.017,0.053,3.026,1.198,3.036,3.438c0,0.147-0.005,0.3-0.013,0.457c-0.089,1.899-1.502,4.486-4.245,7.76
      c-2.829,3.43-5.229,5.147-7.2,5.156c-1.226,0-2.244-1.05-3.061-3.151l-0.858-2.88L4.622,9.922C3.997,7.838,3.329,6.798,2.616,6.798
      c-0.156,0-0.697,0.304-1.626,0.91L0,6.537l1.536-1.276l1.511-1.263C4.4,2.914,5.429,2.328,6.135,2.241
      c0.094-0.01,0.188-0.013,0.284-0.013c1.449,0,2.354,1.041,2.709,3.124C9.326,6.54,9.49,7.506,9.623,8.248
      C9.752,8.992,9.86,9.51,9.946,9.805c0.479,1.97,0.995,2.96,1.55,2.968c0.426,0,1.082-0.642,1.968-1.926
      c0.866-1.319,1.332-2.296,1.392-2.932c0.019-0.129,0.026-0.25,0.026-0.362c0-0.861-0.474-1.29-1.418-1.29
      c-0.479,0-0.99,0.102-1.537,0.299c0.98-3.021,2.864-4.534,5.65-4.544C17.655,2.018,17.732,2.018,17.811,2.018z"
		/>
	</svg>
)

export const Instagram = () => (
	<svg viewBox="0 0 512 512">
		<title>Instagram Icon</title>
		<desc>Camera</desc>
		<path
			fill="#8f7400"
			className="fillPath"
			d="M256,49.471c67.266,0,75.233.257,101.8,1.469,24.562,1.121,37.9,5.224,46.778,8.674a78.052,78.052,0,0,1,28.966,18.845,78.052,78.052,0,0,1,18.845,28.966c3.45,8.877,7.554,22.216,8.674,46.778,1.212,26.565,1.469,34.532,1.469,101.8s-0.257,75.233-1.469,101.8c-1.121,24.562-5.225,37.9-8.674,46.778a83.427,83.427,0,0,1-47.811,47.811c-8.877,3.45-22.216,7.554-46.778,8.674-26.56,1.212-34.527,1.469-101.8,1.469s-75.237-.257-101.8-1.469c-24.562-1.121-37.9-5.225-46.778-8.674a78.051,78.051,0,0,1-28.966-18.845,78.053,78.053,0,0,1-18.845-28.966c-3.45-8.877-7.554-22.216-8.674-46.778-1.212-26.564-1.469-34.532-1.469-101.8s0.257-75.233,1.469-101.8c1.121-24.562,5.224-37.9,8.674-46.778A78.052,78.052,0,0,1,78.458,78.458a78.053,78.053,0,0,1,28.966-18.845c8.877-3.45,22.216-7.554,46.778-8.674,26.565-1.212,34.532-1.469,101.8-1.469m0-45.391c-68.418,0-77,.29-103.866,1.516-26.815,1.224-45.127,5.482-61.151,11.71a123.488,123.488,0,0,0-44.62,29.057A123.488,123.488,0,0,0,17.3,90.982C11.077,107.007,6.819,125.319,5.6,152.134,4.369,179,4.079,187.582,4.079,256S4.369,333,5.6,359.866c1.224,26.815,5.482,45.127,11.71,61.151a123.489,123.489,0,0,0,29.057,44.62,123.486,123.486,0,0,0,44.62,29.057c16.025,6.228,34.337,10.486,61.151,11.71,26.87,1.226,35.449,1.516,103.866,1.516s77-.29,103.866-1.516c26.815-1.224,45.127-5.482,61.151-11.71a128.817,128.817,0,0,0,73.677-73.677c6.228-16.025,10.486-34.337,11.71-61.151,1.226-26.87,1.516-35.449,1.516-103.866s-0.29-77-1.516-103.866c-1.224-26.815-5.482-45.127-11.71-61.151a123.486,123.486,0,0,0-29.057-44.62A123.487,123.487,0,0,0,421.018,17.3C404.993,11.077,386.681,6.819,359.866,5.6,333,4.369,324.418,4.079,256,4.079h0Z"
		/>
		<path
			fill="#8f7400"
			className="fillPath"
			d="M256,126.635A129.365,129.365,0,1,0,385.365,256,129.365,129.365,0,0,0,256,126.635Zm0,213.338A83.973,83.973,0,1,1,339.974,256,83.974,83.974,0,0,1,256,339.973Z"
		/>
		<circle
			fill="#8f7400"
			className="fillPath"
			cx="390.476"
			cy="121.524"
			r="30.23"
		/>
	</svg>
)

export const Facebook = () => (
	<svg id="FacebookIcon" data-name="Layer 1" viewBox="0 0 1024 1024">
		<title>Facebook Icon</title>
		<desc>F</desc>
		<path
			className="fillPath"
			fill="#8f7400"
			d="M967.48,0h-911A56.51,56.51,0,0,0,0,56.52v911A56.51,56.51,0,0,0,56.52,1024H547V628H414V473H547V358.93c0-132.26,80.77-204.28,198.76-204.28,56.51,0,105.08,4.21,119.24,6.09V299l-81.37,0c-64.2,0-76.63,30.51-76.63,75.28V473H860.49l-20,155H707v396H967.48A56.51,56.51,0,0,0,1024,967.48v-911A56.51,56.51,0,0,0,967.48,0Z"
		/>
		<path
			id="f"
			fill="#282519"
			d="M707,1024V628H840.5l20-155H707V374.28c0-44.77,12.43-75.28,76.63-75.28L865,299V160.74c-14.16-1.88-62.73-6.09-119.24-6.09-118,0-198.76,72-198.76,204.28V473H414V628H547v396Z"
		/>
	</svg>
)
