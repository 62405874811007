import React from 'react'
import PropTypes from 'prop-types'
import Helmet from 'react-helmet'
import {StaticQuery, graphql} from 'gatsby'

function SEO({description, lang, meta, keywords, title, image}) {
	return (
		<StaticQuery
			query={graphql`
				query DefaultSEOQuery {
					site {
						m: siteMetadata {
							title
							description
							author
							keywords
						}
					}
				}
			`}
			render={data => {
				const metaDescription = description || data.site.m.description
				const imageUrl = image && image.file ? image.file.url : image

				return (
					<Helmet
						htmlAttributes={{
							lang,
						}}
						title={title}
						titleTemplate={`%s | ${data.site.m.title}`}
						meta={[
							{
								name: `description`,
								content: metaDescription,
							},
							{
								property: `og:title`,
								content: title,
							},
							{
								property: `og:description`,
								content: metaDescription,
							},
							{
								property: `og:type`,
								content: `website`,
							},
							{
								property: `og:image`,
								content: imageUrl,
							},
							{
								name: `twitter:card`,
								content: `summary`,
							},
							{
								name: `twitter:creator`,
								content: data.site.m.author,
							},
							{
								name: `twitter:title`,
								content: title,
							},
							{
								name: `twitter:description`,
								content: metaDescription,
							},
						]
							.concat(
								keywords.length > 0
									? {
											name: `keywords`,
											content: keywords.join(`, `),
									  }
									: []
							)
							.concat(meta)}
					/>
				)
			}}
		/>
	)
}

SEO.defaultProps = {
	lang: `en`,
	meta: [],
	keywords: [],
}

SEO.propTypes = {
	description: PropTypes.string,
	lang: PropTypes.string,
	meta: PropTypes.array,
	keywords: PropTypes.arrayOf(PropTypes.string),
	title: PropTypes.string.isRequired,
	image: PropTypes.oneOf([
		PropTypes.string,
		PropTypes.shape({
			file: PropTypes.shape({
				url: PropTypes.string.isRequired,
			}).isRequired,
		}),
	]),
}

export default SEO

export const contentfulMetadataFragment = graphql`
	fragment ContentfulMetadataFragment on ContentfulMetadata {
		title
		description
		keywords
		image {
			file {
				url
			}
		}
	}
`
